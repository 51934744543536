* {
  margin:0;
  padding:0;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background: #080808;
  color: #fff;
  height: 100%;
}

#header {
  width: 100%;
  height: 100vh;
  background-image: url(./Media/background-final.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#header-event {
  width: 100%;
}

.container {
  padding: 10px 10%;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

nav a {
  text-decoration: None;
  
}

.logo {
  width: 125px;
  padding-top: 10px;
}

nav ul li {
  display: inline-block;
  list-style: None;
  margin: 10px 20px;
}

nav ul li a {
  color: white;
  text-decoration: None;
  font-size: 18px;
  position: relative;
}

nav ul li a::after {
  content: "";
  width: 0;
  height: 3px;
  background: #ff004f;
  position: absolute;
  left: 0;
  bottom: -6px;
  transition: 0.5s;
}

nav ul li a:hover::after {
  width: 100%;
}

.header-text {
  margin-top: 20%;
  font-size: 30px;   
}

.header-text h1 {
  font-size: 60px;
  margin-top: 20px;
}

.header-text h1 span {
  color: #ff004f;
}

#about {
  padding: 80px 0;
  color: #ababab;
}

.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.about-col-1 {
  flex-basis: 35%;
}

.about-col-1 img {
  width: 100%;
  border-radius: 15px;
}

.about-col-2 {
  flex-basis: 60%;
}

#external_link a {
  text-decoration: None;
  color: #b54769;
}

.sub-title {
  font-size: 60px;
  font-weight: 600;
  color: #fff;
}

.tab-titles {
  display: flex;
  margin: 20px 0 40px;  
}

.tab-links {
  margin-right: 50px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  position: relative; 
}

.tab-links::after {
  content: "";
  width: 0;
  height: 3px;
  background: #ff004f;
  position: absolute;
  left: 0;
  bottom: -8px;
  transition: 0.5s;
}

.tab-links.active-link::after {
  width: 50%;
}

.tab-contents ul li {
  list-style: None;
  margin: 10px 0;
}

.tab-contents ul li span {
  color: #b54769;
  font-size: 14px;
}


#services {
  padding: 30px 0;
}

.services-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 40px;
  margin-top: 50px;
}

.services-list div {
  background: #262626;
  padding: 40px;
  font-size: 13px;
  font-weight: 300;
  border-radius: 10px;
  transition: background 0.5s, transform 0.5s;
}

.services-list .video-div {
  padding: 7px;
}

.services-list .driver-project {
  height: 30vh;
}

.services-list div video {
  width: 100%;
  height: 100%;
}

.services-list div i {
  font-size: 50px;
  margin-bottom: 30px;
}

.services-list div h2 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 15px;
}

.services-list div a {
  text-decoration: None;
  color: #fff;
  font-size: 12px;
  margin-top: 20px;
  display: inline-block;
}

.services-list div:hover {
  background: #ff004f;
  transform: translateY(-10px);
}

#know-more {
  margin-top: 50px;
}

#know-more i {
  font-size: 50px;
}

#know-more a {
  text-decoration: None;
  color:#fff;
}

#portfolio {
  padding: 50px 0;
}

.work-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 40px;
  margin-top: 50px;
}

.work {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.work img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: block;
  transition: transform 0.5s;
}

.layer {
  width: 100%;
  height: 0;
  background: linear-gradient(rgba(0,0,0, 0.5), #ff004f);
  border-radius: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  font-size: 14px;
  transition: height 0.5s;
}

.layer h3 {
  font-weight: 500;
  margin-bottom: 20px;
}

.layer a {
  margin-top: 20px;
  text-decoration: None;
  color: #ff004f;
  font-size: 18px;
  line-height: 60px;
  background: #fff;
  width: 60px;
  height: 60px;
  text-align: center;
  border-radius: 50%;
}

.work:hover img {
  transform: scale(1.1);
}

.work:hover .layer {
  height: 100%;
} 

.btn {
  display: block;
  margin: 50px auto;
  width: fit-content;
  border: 1px solid #ff004f;
  padding: 14px 50px;
  border-radius: 6px;
  text-decoration: None;
  color: #fff;
  transition: background 0.5s;
}

.btn:hover {
  background: #ff004f;
}

.contact-left {
  flex-basis: 35%;
}

.contact-right {
  flex-basis: 60%;
}

.contact-left p {
  margin-top: 30px;
}

.contact-left p i {
  color: #ff004f;
  margin-right: 15px;
  font-size: 25px;
}

.social-icons {
  margin-top: 30px;
}

.social-icons a {
  text-decoration: None;
  font-size: 30px;
  margin-right: 15px;
  color: #ababab;
  display: inline-block;
  transition: transform 0.5s;
}

.social-icons a:hover {
  color: #ff004f;
  transform: translateY(-5px);
}

.btn.btn2 {
  display: inline-block;
  background: #ff004f;
}

.contact-right form {
  width: 100%;
}

form input, form textarea {
  width: 100%;
  border: 0;
  outline: None;
  background: #262626;
  padding: 15px;
  margin: 15px 0;
  color: #fff;
  font-size: 18px;
  border-radius: 6px;
}

form .btn2 {
  font-size: 18px;
  margin-top: 20px;
  padding: 14px 60px;
  cursor: pointer;
}

.copyright {
  width: 100%;
  text-align: center;
  padding: 25px 0;
  background: #262626;
  font-weight: 300;
  margin-top: 20px;
}

.copyright i {
  color: #ff004f
}

.project {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

nav .fas {
  display: None;
}

.btn.btn-danger {
  background-color: #ff004f;
  color: #fff; /* White text color */
  border-color: #dc3545;
}

.btn.btn-danger:hover {
  background-color: #ff004f; 
  border-color: #bd2130;
}

.btn.btn-danger:focus,
.btn.btn-danger.focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

@media only screen and (max-width: 1024px) {
  #header {
      background-image: url("./Media/background-tablet.jpg");
  }
  
}

@media only screen and (max-width: 600px) {
  #header {
      background-image: url("./Media/background-phone.jpg");
  }

  .header-text {
      margin-top: 100%;
      font-size: 16px;
  }

  .header-text h1 {
      font-size: 30px;
  }

  nav .fas {
      display: block;
      font-size: 25px;
  }

  nav ul {
      background: #ff004f;
      position: fixed;
      top: 0;
      right: -200px;
      width: 200px;
      height: 100vh;
      padding-top: 50px;
      z-index: 2;
      transition: right 0.5s;
  }

  nav ul li {
      display: block;
      margin: 25px;   
  }

  nav ul .fas {
      position: absolute;
      top: 25px;
      left: 25px;
      cursor: pointer;
  }

  .sub-title {
      font-size: 40px;
  }

  .about-col-1, .about-col-2 {
      flex-basis: 100%;
  }

  .about-col-1 {
      margin-bottom: 30px;
  }

  .about-col-2 {
      font-size: 14px;
  }

  .tab-links {
      font-size: 16px;
      margin-right: 20px;
  }

  .contact-left, .contact-right {
      flex-basis: 100%;
  }

  .copyright {
      font-size: 14px;
  }
}

#msg {
  color: #61b752;
  margin-top: -35px;
  display: block;
}


@media only screen and (max-width: 420px) {
  .services-list div h2 {
      font-size: 25px;
  }
}

@media only screen and (max-width: 1000px) {
  #project-heading {
      font-size: 50px;
  }
}

@media only screen and (max-width: 600px) {
  #project-heading {
      font-size: 30px;
  }

  #project-container p {
      font-size: 12px;   
  }
}